const cookieTrigger = document.querySelector('[data-behaviour=\'cookies\']');

if(cookieTrigger) {

    const simulateClick = (elem) => {
        // Create our event (with options)
        var evt = new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
        });
        // If cancelled, don't dispatch our event
        var canceled = !elem.dispatchEvent(evt);
    };

    cookieTrigger.addEventListener('click', (e) => {

        const cookieWidget = document.querySelector('.CookiebotWidget-logo');

        if( cookieWidget ) {
            simulateClick(cookieWidget);
            e.preventDefault();
        }
        
    });

};