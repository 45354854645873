const triggers = document.querySelectorAll('[data-behaviour=\'scroll\']');

triggers.forEach((scrollTrigger) => {

  scrollTrigger.addEventListener('click', (e) => {

    const target = scrollTrigger.getAttribute('data-target');

    if( target === 'top' ) {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    } else {

        const element = document.querySelector(target);

        if( element ) {
            element.scrollIntoView({
                behavior: "smooth"
            });
        }

    }

    e.preventDefault();
  });
});
