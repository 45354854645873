
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import 'swiper/css';

const swiper = new Swiper(".swiper", {
    modules: [Navigation],
    slidesPerView: 2.4,
    spaceBetween: 30,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    breakpoints: {
        "0": {
          slidesPerView: 1.2,
        },
        "767": {
          slidesPerView: 2.4,
        }
    },
});
