const comparisons = document.querySelectorAll('[data-behaviour=\'compare\']');

let active = false;
let observer;

if( comparisons.length > 0 ) {

    const dragDown = (e, element) => {
        active = true;
        element.classList.add('active');
        e.preventDefault();
    };
    
    const dragUp = () => {
        active = false;
        comparisons.forEach((compare) => {
            const handle = compare.querySelector('.compare__handle');
            if( handle) {
                compare.classList.remove('active');
            }
        });
    };

    const dragMove = (e) => {
        if (!active) return;

        let x = e.pageX;

        // For Touch Devices
        if(!x && e.changedTouches) {
            x = e.changedTouches[0].pageX;
        }

        x -= document.querySelector('.compare.active').getBoundingClientRect().left;
        compareDrag(x);
    };

    const compareDrag = (x) => {
        const compare = document.querySelector('.compare.active');
        let percentage =  Math.max(0,(Math.min( ((x/compare.offsetWidth) * 100), 100)));
        compare.querySelector('.compare__overlay').style.width = percentage + '%';
        compare.querySelector('.compare__handle').style.left = percentage + '%';
    }

    const onScroll = () => {

        observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        dragDemo(entry.target);
                    }
                });
            },
            { threshold: 0.65 }
        );

        [...document.querySelectorAll('[data-behaviour=\'compare\']')].map(
            (section) => observer.observe(section)
        );
    }

    const dragDemo = (element) => {

        element.classList.add('is-demoing');
        moveDrag(element, 55);

        setTimeout(() => {
            moveDrag(element, 45);
        }, 500);

        // Remove observer
        setTimeout(() => {
            moveDrag(element, 50);
        }, 1000); 

        setTimeout(() => {
            element.classList.remove('is-demoing');
            observer.unobserve(element);
        }, 2000); 

    }

    const moveDrag = (element, percentage) => {
        let overlay = element.querySelector('.compare__overlay');
        let handle = element.querySelector('.compare__handle');
        overlay.style.width = percentage + '%';
        handle.style.left = percentage + '%';
    }
    
    comparisons.forEach((compare) => {
    
        const handle = compare.querySelector('.compare__handle');
        handle.addEventListener('mousedown', (e) => {
            dragDown(e, compare);
        });
        handle.addEventListener('touchstart', (e) => {
            dragDown(e, compare);
        });
    
    });

    document.body.addEventListener('mouseup',function(){
        dragUp();
    });

    document.body.addEventListener('mouseleave',function(){
        dragUp();
    });

    document.body.addEventListener('touchend',function(){
        dragUp();
    });

    document.body.addEventListener('touchcancel',function(){
        dragUp();
    });

    document.body.addEventListener('mousemove',function(e){
        dragMove(e);
    });

    document.body.addEventListener('touchmove',function(e){
        dragMove(e);
    });

    onScroll();

}